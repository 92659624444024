<template>
    <div class="w-full">
        <label
            v-if="label"
            class="mb-1 block text-sm font-medium text-gray-700"
        >
            <slot>{{ label }}</slot>
        </label>
        <Listbox
            v-model="selectedItem"
            as="div"
            :class="{ 'w-full': expanded }"
        >
            <div class="relative">
                <ListboxButton
                    class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                >
                    <span class="block h-5 truncate">{{ current?.label }}</span>
                    <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                        <ChevronUpDownIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </ListboxButton>

                <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                        <ListboxOption
                            v-for="(item, i) in items"
                            v-slot="{ active, selected }"
                            :key="i"
                            as="template"
                            :value="item.value"
                        >
                            <li
                                :class="[
                                    active
                                        ? 'bg-primary text-primary-bw'
                                        : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                ]"
                            >
                                <span
                                    :class="[
                                        selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                        'block truncate',
                                    ]"
                                >
                                    {{ item.label }}
                                </span>

                                <span
                                    v-if="selected"
                                    :class="[
                                        active
                                            ? 'text-primary-bw'
                                            : 'text-primary',
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                    ]"
                                >
                                    <GFIcon
                                        icon="check"
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from "vue"
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue"
import GFIcon from "./GFIcon.vue"
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid"

export type GFSelectOption<TLabel = string, TValue = string> = {
    label: TLabel
    value: TValue
}

const emit = defineEmits<{
    (e: "update:current", item: GFSelectOption): void
}>()

const props = defineProps({
    current: {
        type: [Object, null] as PropType<GFSelectOption | null>,
        required: true,
    },
    items: {
        type: Array as PropType<Readonly<GFSelectOption[]>>,
        default: () => [],
    },
    expanded: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: null,
    },
})

const selectedItem = computed({
    get() {
        return props.current?.value
    },
    set(value) {
        const item = props.items.find((item) => item.value === value)
        if (item) emit("update:current", item)
    },
})
</script>
